export function assert(check, message, ...additionalMessageEntries) {
    if (check instanceof Promise) {
        check.then((checkResult) => {
            if (!checkResult) {
                if (additionalMessageEntries != null) {
                    console.error(typeof message === "string" ? message : message(), ...additionalMessageEntries);
                }
                else {
                    console.error(typeof message === "string" ? message : message());
                }
            }
        });
    }
    else if (check === false) {
        if (additionalMessageEntries != null) {
            console.error(typeof message === "string" ? message : message(), ...additionalMessageEntries);
        }
        else {
            console.error(typeof message === "string" ? message : message());
        }
    }
}
export function assertConditional(condition, check, message) {
    if (condition) {
        if (check instanceof Promise) {
            check.then((checkResult) => {
                if (!checkResult) {
                    console.error(typeof message === "string" ? message : message());
                }
            });
        }
        else if (check === false) {
            console.error(typeof message === "string" ? message : message());
        }
    }
}
export function assertPropertyValue(tagName, propertyName, propertyValue, possibleValues, ...additionalMessageEntries) {
    assert(possibleValues.includes(propertyValue), () => {
        return `<${tagName}> doesn't support for property '${propertyName}' a value '${propertyValue}'. Possible values are ${possibleValues
            .map((v) => `'${v}'`)
            .join(", ")}.`;
    }, ...additionalMessageEntries);
}
/**
 * Helper function to assert deprecated properties of an element.
 */
export function assertDeprecatedProperty(obj, property, additionalMessage = "") {
    assert(!obj[property], () => `The "${property === null || property === void 0 ? void 0 : property.toString()}" property will be removed within the next releases. ${additionalMessage}.`, obj);
}
/**
 * Helper function to assert deprecated properties of an element.
 */
export function assertDeprecatedPropertyByPredicate(obj, property, predicate, additionalMessage = "") {
    assert(predicate(), () => `The "${property === null || property === void 0 ? void 0 : property.toString()}" property will be removed within the next releases. ${additionalMessage}.`, obj);
}
export function assertPropertyValueByPredicate(tagName, propertyName, propertyValue, predicate, additionalText = "") {
    assert(predicate(), () => {
        return `<${tagName}> doesn't support for property '${propertyName}' a value '${propertyValue}'. ${additionalText}`;
    });
}
export function assertPropertyNotEmpty(tagName, propertyName, propertyValue, additionalText = "") {
    assert(!(propertyValue === "" || propertyValue === undefined), () => {
        return `The property '${propertyName}' in <${tagName}> is empty or unset. ${additionalText}`;
    });
}
